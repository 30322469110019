
export default function Releases() {

    return (
	<>
	    <div class="bio">
	    <div class="bioText">
	    <div>
	    <img src="/MTuckman.png" width="238px" height="238px"/>
	</div>
	    <div>Michael Tuckman</div>
	    </div>
	    <div><p>
A veteran of the independent film industry for over 25 years, Michael Tuckman began his career at The Cinema Guild, where he was hired to start the company's theatrical distribution division. Tuckman went on to serve as Vice President of Theatrical Sales for THINKFilm, handling the planning and implementation of all theatrical release strategies, including the breakout successes of Oscar-winning and nominated films including SPELLBOUND, HALF NELSON, BORN INTO BROTHELS, TAXI TO THE DARK SIDE and MURDERBALL as well as the box office smashes THE ARISTOCRATS, BEFORE THE DEVIL KNOWS YOU'RE DEAD and THEN SHE FOUND ME.
</p><p>
Since 2009, he has operated his own distribution services company, mTuckman media, with which he works directly with filmmakers under their own banners. Most notably, he has handled Koganada's COLUMBUS, which grossed over $1 million and was nominated for both Spirit and Gotham Awards. Releases also include Shane Carruth's UPSTREAM COLOR; DETROPIA, from the Academy Award nominated directors Heidi Ewing and Rachel Grady; and Rory Kennedy's Academy Award nominated LAST DAYS IN VIETNAM.
</p><p>
Others collaborations include Frederick Wiseman's LA DANSE: THE PARIS OPERA BALLET, BOXING GYM, CRAZY HORSE, AT BERKELEY, NATIONAL GALLERY, IN JACKSON HEIGHTS, EX LIBRIS, MONROVIA, INDIANA, CITY HALL and UN COUPLE, as well as national releases for Sundance standouts such as AMERICAN PROMISE, Robert Greene's BISBEE '17 and Amy Berg's PROPHET'S PREY. MTM handled the theatrical booking for the Academy Award nominated documentaries THE SQUARE and FOR SAMA, as well as WE COME AS FRIENDS, which was named to the shortlist for the Academy Award for Best Documentary.
</p><p>
His company also provides theatrical booking and consultation services to distributors,  with releases including the 2012, 2013 and 2014 Academy Award nominees for Best Foreign Language film, BULLHEAD, WAR WITCH, and THE BROKEN CIRCLE BREAKDOWN as well as the 2014 Academy Award nominated documentary, THE ACT OF KILLING, in addition to leading the breakout indie hit of 2015. WHAT WE DO IN THE SHADOWS - to a gross of over $3,500,000 in domestic box office. Additional releases include Takashi Miike's FIRST LOVE, THE LITTLE HOURS and WAKE IN FRIGHT.
</p><p>
                 Since 2020, Tuckman has also overseen all releases from MTV Documentary Films,                       including Academy Award nominee ASCENSION, the Oscar-shortlisted films 76                             DAYS and LAST FLIGHT HOME, and THE ETERNAL MEMORY, winner of the Grand                     Jury prize for World Documentary at Sundance.
</p><p>
Tuckman is an Associate Assistant Professor at the Feirstein Graduate School of Cinema at Brooklyn College, teaching a survey course on Theatrical Distribution and Exhibition at the Steiner Studios campus.</p>
</div>
</div>
</>
    )
}

