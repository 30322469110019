import {useEffect, useState} from 'react';
import {
    Link, useSearchParams,useNavigate,
} from "react-router-dom";


export default function Releases(props) {
    const {categoryNumber, pathName} = props
    const categories = {
	5: 'releases',
	2: 'booking',
    }

    const category = categories[categoryNumber];
    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const [releases, setReleases]  = useState({})

    useEffect(()=>{
	window.scrollTo(0, 0)
	try {
	    throw new TypeError("oops");
	    let currItems = JSON.parse(localStorage.getItem(category));
	    if(Object.keys(currItems).length>1){
		setReleases(currItems);
	    }
	} catch(err){
	    let parser = new DOMParser();
	    fetch(`https://mtuckmanmedia.com/wordpress/?rest_route=/wp/v2/posts/&per_page=100&&orderby=date&order=desc&categories=${categoryNumber}`).then(
		function(response) {
                    return response.json()
		})
		.then(function (jsondata){
		    const releases = {};
		    jsondata.forEach(function(item, i){
			const content = item.content.rendered
			const regex = /src="([^"]+)"/;
			const imgSrc = regex.exec(content)[1];
			const title = parser.parseFromString(item.title.rendered, "text/html").body.textContent;
			releases[item.id] = {imgSrc: imgSrc, content: item.content.rendered, title: title};
		    })

                    localStorage.setItem(category, JSON.stringify(releases));
		    setReleases(releases);
		})
	};
    }, [pathName]);
	
    const printItems = () =>{

	const imgSrcArray = Object.values(releases).map(item => item.imgSrc);

	// we are adding our items in reverse order, so that the latest date is first
	const items = []
	Object.keys(releases).map(function(item, i){
               items.unshift(
                   <div key={i}>
		       <Link to={`/${pathName}?id=${item}`}>
			   <img src={releases[item].imgSrc} />
		       </Link>
                   </div>
               )
	});
	return items;
    }


    const popUpMovieId = searchParams.get("id");

    return (
	<>
	    <div className="posterWall">
		<div className="posterContainer">
		{printItems()}
		</div>
	    </div>
	    {releases[popUpMovieId] &&
	     <div className="backgroundCover" onClick={()=>navigate(`/${pathName}`)}>
		 
		 <div className="whiteHolder">
		     <div className="closeButton">
			 <img src="/closeButton.svg" />
		     </div>
	     <div>
	     <div className='movieContent' dangerouslySetInnerHTML={{ __html: releases[popUpMovieId].content }} />
	     </div>
	     
	     </div>
	     </div>
	     
	    }
		   
	</>

    )
}

