import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './home';
import logo from './logo.svg';
import './App.css';


function App() {
    return (
	    <div className="App">
	    <Home />
	    </div>
  );
}

export default App;
