import {useState} from "react";
import {
    Link, useLocation
} from "react-router-dom";

export default function Header(){
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const pathName = useLocation()?.pathname;

    const toggleMenu=()=>{
	setShowMobileMenu(!showMobileMenu)
	console.log('clcikc', showMobileMenu);
    }
    return(
	<>
	    <div className="header">

	<div>
	    <Link to={'/home'}>
	    <img src='/mtuckmanlogo.svg' width="235px" height="20px"/></Link>
	    </div>
	    <div className={`${pathName === '/releases'?'orangeLink':''}`}>
	    <Link to={'/releases'}>Theatrical Releases</Link>
	    </div> 

	    <div className={`${pathName === '/booking'?'orangeLink':''}`}>
	    <Link to={'/booking'}>Theatrical Booking Services</Link>
	    </div>

	    <div  className={`${pathName === '/programming'?'orangeLink':''}`}>
	    <Link to={'/programming'}>Film Festival Programming</Link>
	    </div>

	    <div className={`${pathName === '/bio'?'orangeLink':''}`}>
	    <Link to={'/bio'}>Bio</Link>
	    </div>	    
	    </div>
	    <div>

	<div className="mobileCloseBurger">
	   
	{showMobileMenu?
	 (
		 <div onClick={toggleMenu}><img src='https://mtuckman.s3.amazonaws.com/icons/close.svg' width="40px" height="40px" /></div>
	    ):(
		    <div onClick={toggleMenu}><img src='https://mtuckman.s3.amazonaws.com/icons/hamburger.svg'
		width="40px" height="40px"/></div>
	    )
	 }
		</div>
	    <div onClick={toggleMenu} className={`mobileHeader ${showMobileMenu && 'showMobile'}`}>
	   
	    <div onClick={toggleMenu} className="mobileBackgroundCover"></div>
	    
	    <div onClick={toggleMenu} className="linkHolder">

	    <div className="mobile_nav">

	    <div className={`mobileLink ${pathName === '/releases'?'orangeLink':''}`}>
	    <Link to={'/releases'}>Theatrical Releases</Link>
	    </div> 

	    <div className={`mobileLink ${pathName === '/booking'?'orangeLink':''}`}>
	    <Link to={'/booking'}>Theatrical Booking Services</Link>
	    </div>

	    <div  className={`mobileLink ${pathName === '/programming'?'orangeLink':''}`}>
	    <Link to={'/programming'}>Film Festival Programming</Link>
	    </div>

	    <div className={`mobileLink ${pathName === '/bio'?'orangeLink':''}`}>
	    <Link to={'/bio'}>Bio</Link>
	    </div>

	</div>

	</div>

	</div>
	</div>
	    </>
    )
}
