import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './home';
import Header from './header';
import Footer from './footer';
import Releases from './releases';
import Bio from './bio';
import Programming from './programming';
import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
    HashRouter
} from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';


const pageRender = (type, isWhite=false) =>{
    return (
	<>
	    <Header />
	    <div className={`${isWhite?'whiteContainer':''} container`}>
		{type}
		<Footer isWhite={isWhite} />
	    </div>
	</>
    )
}

const router = createHashRouter([
  {
    path: "/",
      element: <Home />,
      errorElement: <Home />, 
  },
    {
    path: "/releases",
	element: pageRender(<Releases categoryNumber={5} pathName={"releases"} />),
    },
    {
    path: "/booking",
	element: pageRender(<Releases categoryNumber={2} pathName={"booking"} />),
    },
    {
    path: "/programming",
	element: pageRender(<Programming />, true),
    },
    {
    path: "/bio",
	element: pageRender(<Bio />, true),
    },
    
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
