export default function Programming(){

    const festivalData = [
	{img: 'cinequest.png', title: 'Cinequest International Film Festival', location: 'San Jose, CA', content:'MTM has programmed Opening and Closing Night films since 2010 along with the centerpiece “Spotlight Screenings” to supply high profile films to the stellar lineup of nearly 200 films. Titles include major films from distributors such as Sony Pictures Classics, Magnolia Pictures, Searchlight, Lionsgate, Music Box Films, and others. Also arranged for and secured celebrity guest appearances at the festival, including John Turturro, Salman Rushdie, Terence Davies, AnnaSophia Robb, Miranda Otto, and Krysten Ritter.', link: 'https://www.cinequest.org/'},
	{img:'keywest.png', title:'Key West Film Festival', location:'Key West, FL', content:'Michael Tuckman serves as Director of Programming for the festival, now in its 9th year and renowned for its Critics’ Focus program and annual award for Costume Design. MTM arranged appearances of Burt Reynolds, Brett Ratner, Paul Verhoeven, Marisa Tomei, Alan Cumming, Mariel Hemingway, John Waters, Sebastian Junger, Shane Carruth, and Amy Seimetz, as well as other visiting actors, directors and critics, including Eric Kohn, Kenneth Turan, Ann Hornaday, David Fear, Amy Nicholson, Jeffrey Wells, Joshua Rothkopf, Justin Chang, and Emily Yoshida. The festival has showcased major awards season films including Roma, If Beale Street Could Talk, Spotlight, Manchester by the Sea, The Shape of Water, Last Flag Flying, Carol, 20th Century Women, and more.', link:'http://kwfilmfest.com/'},
    ]

    const printFestivals = festivalData.map(fest=>(
	    <div className="festivalsContainer" onClick={()=>{window.open(fest.link, '_blank');}}>
	    <img src={`festivals/${fest.img}`} width="269px" height="269px"/>
	    <div>
	    <h2>{fest.title}</h2>
	    <p>{fest.content}</p>
	    <div><img src="https://mtuckman.s3.amazonaws.com/icons/map.svg" /> {fest.location}</div>
	    </div>
	</div>
    ))
					    
    return (
	<>
	    <div className="festivalsHeader">
	    <img src='/festivals/festivals.png' />
	    <div>film festivals</div>
	    </div>
	    {printFestivals}
	    </>
    );
}
