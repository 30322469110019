import {
    Link, useSearchParams
} from "react-router-dom";


export default function Footer(props){

    let [searchParams, setSearchParams] = useSearchParams();
    if (searchParams.get("id")) return; // hides footer on item load
    const { isWhite } = props;
    const phoneNumber = '212 334-4858';
    const email = 'info@mtuckmanmedia.com';
    const address= '285 West Broadway, Suite 320  New York, NY 10013';
    return (
	    <div className={`footer ${isWhite?'whiteBackground':''}`}>
	    <div>
	    <Link to={`tel:${phoneNumber}`} target="_blank">
		      <img src='https://mtuckman.s3.amazonaws.com/icons/phone.svg' className="footerButton" />{phoneNumber}
		      </Link>
	</div>
	   <div className="middle">
	    <img src='/mtuckmanlogo.svg' width="235px" height="20px"/>
	    </div>
	    <Link to={`https://www.google.com/maps/place/${address}`} target="_blank">
	    <div className="address">

	    <div>
	    <img src='https://mtuckman.s3.amazonaws.com/icons/map.svg' className="footerButton" />
	    </div><div>
	    {address}
	</div>

	</div>
	    </Link>
	    <div>
	    	    <Link to={`mailto:${email}`} target="_blank">
	    <img src='https://mtuckman.s3.amazonaws.com/icons/email.svg' className="footerButton" />{email}
	</Link>
       	</div>
	   
	    <div className="middle"><strong>
	    {`© Mtuckman Media, ${new Date().getFullYear()}`}
	</strong>
	    </div>	    
   
</div>

    )
}
