import {
  Link
} from "react-router-dom";


function Home() {
    const images = [];
    const fillImages = [
  "https://s3.amazonaws.com/mtuckman/images/boxing.png",
	"http://mtuckmanmedia.com/wordpress/wp-content/uploads/2020/12/76days.jpg",
  "https://s3.amazonaws.com/mtuckman/images/crzyhorse.png",
	"https://s3.amazonaws.com/mtuckman/images/American.jpg",
			"https://s3.amazonaws.com/mtuckman/images/upstream.png",
  "https://s3.amazonaws.com/mtuckman/images/caucus.jpg",
  "https://s3.amazonaws.com/mtuckman/images/atBerkley.jpg",
	"https://s3.amazonaws.com/mtuckman/images/dance.jpg",
	"https://s3.amazonaws.com/mtuckman/images/NG-Poster.jpg",
	"http://mtuckman.s3.amazonaws.com/wordpress/wp-content/uploads/2019/12/13224541/MV5BYzE2NWE0OWEtMjk5NS00OWYwLWEwNzQtMGFhZTIzYTgxZjI0XkEyXkFqcGdeQXVyNDY2NDMxNDY%40._V1_.jpg",
  "https://s3.amazonaws.com/mtuckman/images/What-We-Do-In-The-Shadows1-122x180.jpg",
  "https://s3.amazonaws.com/mtuckman/images/IJH-Poster.jpg",
  "https://s3.amazonaws.com/mtuckman/images/Too-Late-Movie-Poster.jpg",
	"https://s3.amazonaws.com/mtuckman/images/DoNotResist.jpg",
  "https://s3.amazonaws.com/mtuckman/images/Unrest-poster.jpg",
	"http://mtuckman.s3.amazonaws.com/wordpress/wp-content/uploads/2018/12/03235146/monrovia_indiana_xlg.jpg",
	"http://mtuckman.s3.amazonaws.com/wordpress/wp-content/uploads/2019/12/20212010/loveantoshaposter.jpg",
  "https://s3.amazonaws.com/mtuckman/images/HERBDOROTHY.jpg",
  "http://mtuckman.s3.amazonaws.com/wordpress/wp-content/uploads/2019/12/13224540/MV5BMmE2ZThjNmMtNDlhNC00MDI1LWEzYzItY2NlNjI1MGMyYWYzXkEyXkFqcGdeQXVyMjIyNDEyMDA%40._V1_UY268_CR40182268_AL_.jpg",
  "https://mtuckmanmedia.com/wordpress/wp-content/uploads/2022/02/Without-Getting-Killed-or-Caught.jpeg",
  "https://mtuckmanmedia.com/wordpress/wp-content/uploads/2022/02/Storm-Lake.jpeg",
    ];

    images[2]="https://mtuckmanmedia.com/wordpress/wp-content/uploads/2023/08/Screen-Shot-2023-08-13-at-4.16.04-PM.png"
    images[3]= "https://mtuckmanmedia.com/wordpress/wp-content/uploads/2022/02/Ascension.jpeg"
    images[4]="https://cdn.cinematerial.com/p/297x/w4eaqdtt/deep-in-the-heart-a-texas-wildlife-story-movie-poster-md.jpg"

    images[8]=  "https://s3.amazonaws.com/mtuckman/images/Columbus-Poster-Web.jpg"
    images[9]=	"https://mtuckmanmedia.com/wordpress/wp-content/uploads/2020/12/cityhall.jpg"
    images[10]="http://mtuckman.s3.amazonaws.com/wordpress/wp-content/uploads/2018/12/03235118/36392045_416804342136867_8706170365673472000_o.jpg"
    images[14]=	  "https://s3.amazonaws.com/mtuckman/images/Ex-Libris-poster.jpg"
	images[15]=		"https://s3.amazonaws.com/mtuckman/images/LDV_Poster.jpg"
    images[16]= 	"https://s3.amazonaws.com/mtuckman/images/nuts_xlg.jpg"

    images[20]="https://s3.amazonaws.com/mtuckman/images/wake_in_fright.png"
	images[21]="https://s3.amazonaws.com/mtuckman/images/poster-with-laurels-Nov-11.jpg"
    images[22]="https://mtuckmanmedia.com/wordpress/wp-content/uploads/2022/02/Summertime.jpeg"

    images[26]="https://s3.amazonaws.com/mtuckman/images/Cheatin-Poster.jpg"
    images[27]=   "https://s3.amazonaws.com/mtuckman/images/upstream.png"
    images[28]="http://s3.amazonaws.com/mtuckman/wordpress/wp-content/uploads/2019/12/13224540/FIRST-LOVE-Poster-1382x2048.jpg"
    

    let cnt = 0;

    // fill images from fill list into images where not explicitly assigned
    let fillIdx=0;
    for(let i=0;i<images.length; i++){
	if(images[i]===undefined){
	    images[i]= fillImages[fillIdx];
	    fillIdx++;
	}
    }

    
    const printFilm = (num=6)=>{
	const films = [];

	for(let i=0; i<num; i++){
	    if(cnt===images.length) cnt = 0;
	    
	    const newImg = images[cnt];
	    cnt++;
	    films.push(<img src={newImg} />);
	}
	return (<div className="filmColumn">{films}</div>);
    }

    const printMobileFilms = () => {
    images.length = 22;
    const films = images.map((img, index) => {
        var rotation = Math.floor(Math.random() * 41) - 20; // Random rotation between -20 and 20 degrees
        var top = Math.random() * 8; // Random overlap between -5% and 5%
        var left = Math.random() * 1; // Random overlap between -5% and 5%

        return (
            <img 
                key={index} // Added key prop for unique identifier
                src={img} 
                style={{
                    transform: `rotate(${rotation}deg)`,
                    position: 'relative',
                    top: `${top}%`,
                    left: `${left}%`,
                    width: '117px',
                    height: '175px'
                }} 
            />
        );
    });

	return (

		<div className="mobile_films" style={{  gridTemplateColumns: 'repeat(auto-fill, minmax(117px, 1fr))', gap: '3px' }}>
		{films}

		</div>
    );
}


    return (
	<>
	  <div className="homeNav">
	  <img className="logo" src='/mtuckmanlogo.svg'/>
	    <div className="home nav"><Link to={"/releases"}>Theatrical Releasing</Link></div>
	  <div className="home nav"><Link to={"/booking"}>Theatrical Booking Services</Link></div>

	    <div className="home nav"><Link to={"/programming"}>Film Festival Programming</Link></div>
	    </div>
	    <div className="filmStrip">
	    {printFilm()}
	{printFilm()}
	{printFilm()}
	{printFilm()}
	{printFilm()}
	
	</div>

	    <div className="mobileBackgroundCover"></div>
	    
	    <div>{printMobileFilms()}</div>
	    <div className="mobile_nav home_nav">
	    <Link to={"/releases"}>Theatrical Releasing</Link>
	    <Link to={"/booking"}>Theatrical Booking Services</Link>
	    <Link to={"/programming"}>Film Festival Programming</Link>
	    </div>

	    
	   </>
  );
}

export default Home;
